import Vue from "vue";
import VueRouter from "vue-router";

//UI

Vue.use(VueRouter);

const routes = [
    {
        path: "/preparations/",
        name: "Search",
        component: () => import("../views/Search.vue"),
    },
    {
        path: "/preparations/theme/:theme_slug/",
        name: "Search",
        component: () => import("../views/Search.vue"),
    },
    {
        path: "/preparations/tag/:tag_slug/",
        name: "Search",
        component: () => import("../views/Search.vue"),
    },
    {
        path: "/user/edit/:prep_id/",
        name: "PreparationEdit",
        component: () => import("../views/PreparationEdit.vue"),
    },
    {
        path: "/user/list/",
        name: "PreparationList",
        component: () => import("../views/PreparationList.vue"),
    },
    {
        path: "/accounts/profile/",
        name: "ProfileUpdate",
        component: () => import("../views/ProfileUpdate.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

export default router;
